/* eslint-disable */
// user action constants
import {
    AVAILABLE_SESSION,
    CREATE_AVAILABLE_SESSION_SUCCESS,
    AVAILABLE_SESSIONS_SUCCESS,
    UPDATE_AVAILABLE_SESSION_SUCCESS,
    DELETE_AVAILABLE_SESSION_SUCCESS,
    AVAILABLE_SESSIONS_REPORT
  } from "../actions/types";
  
  // default available session state
  const defaultState = {
    availableSession: {
        id: null,
        title: null,
        coordinator_name: null,
        coordinator_phoneNumber: null,
        coordinator_email: null,
        freeWithSubscription: null,
        sessionPrice: null,
        max_attendance: null,
        attendance_count: null,
        min_age: null,
        max_age: null,
        gender: null,
        startDate: null,
        endDate: null,
        location_id: null,
        sub_location_id: null,
        notes: null,
        date: null
    },
    newSession: {},
    availableSessions: [],
    availableSessionsReport: [],
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case AVAILABLE_SESSION:
        return {
          ...state,
          availableSession: {
            id: action.payload.id,
            title: action.payload.title,
            coordinator_name: action.payload.coordinator_name,
            coordinator_phoneNumber: action.payload.coordinator_phoneNumber,
            coordinator_email: action.payload.coordinator_email,
            freeWithSubscription: action.payload.freeWithSubscription,
            sessionPrice: action.payload.sessionPrice,
            max_attendance: action.payload.max_attendance,
            attendance_count: action.payload.attendance_count,
            min_age: action.payload.min_age,
            max_age: action.payload.max_age,
            gender: action.payload.gender,
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id,
            notes: action.payload.notes,
            date: action.payload.date
          }
        }

      case AVAILABLE_SESSIONS_SUCCESS:
        return {
          ...state,
          availableSessions: action.payload
        }

      case CREATE_AVAILABLE_SESSION_SUCCESS:
        const existingArray = state.availableSessions;
        existingArray.push(action.payload);
        return {
          ...state,
          newSession: action.payload,
          availableSessions: existingArray
        }

      case UPDATE_AVAILABLE_SESSION_SUCCESS:
        const filteredArray = state.availableSessions.filter(availableSession => availableSession.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          availableSessions: filteredArray,
        }

      case DELETE_AVAILABLE_SESSION_SUCCESS:
        const filteredData = state.availableSessions.filter(availableSession => availableSession.id !== action.payload)
        return {
          ...state,
          availableSessions: filteredData
        }
    
      case AVAILABLE_SESSIONS_REPORT:
        return {
          ...state,
          availableSessionsReport: action.payload
        }

      default:
        return state;
    }
  };
  