/* eslint-disable */
// user action constants
import {
    LOCATION,
    CREATE_LOCATION_SUCCESS,
    LOCATIONS_SUCCESS,
    UPDATE_LOCATION_SUCCESS,
    DELETE_LOCATION_SUCCESS
  } from "../actions/types";
import { getLocalStorage } from '../../utilities/localStorage'
const locationId = getLocalStorage("locationId")
const locationName = getLocalStorage("locationName")
const poc_email = getLocalStorage("poc_email")
  
  // default user state
  const defaultState = {
    locationName: {
      id: null || locationId,
      name: null || locationName,
      poc_name: null,
      poc_phoneNumber: null,
      poc_email: null || poc_email,
      city: null,
      state: null,
      lat: null,
      lng: null,
      status: null,
      media_id: null,
      stripe_id: null,
      pricePerSession: null,
    },
    newLocation: {},
    locations: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case LOCATION:
        return {
          ...state,
          locationName: {
            id: action.payload.id,
            name: action.payload.name,
            poc_name: action.payload.poc_name,
            poc_phoneNumber: action.payload.poc_phoneNumber,
            poc_email: action.payload.poc_email,
            city: action.payload.city,
            state: action.payload.state,
            lat: action.payload.lat,
            lng: action.payload.lng,
            status: action.payload.status,
            media_id: action.payload.media_id,
            stripe_id: action.payload.stripe_id,
            pricePerSession: action.payload.pricePerSession,
          }
        }

      case LOCATIONS_SUCCESS:
        return {
          ...state,
          locations: action.payload
        }

      case CREATE_LOCATION_SUCCESS:
        const existingArray = state.locations;
        existingArray.push(action.payload);
        return {
          ...state,
          newLocation: action.payload
        }
  
      case UPDATE_LOCATION_SUCCESS:
        const filteredArray = state.locations.filter(location => location.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          locations: filteredArray,
        }

      case DELETE_LOCATION_SUCCESS:
        const filteredData = state.locations.filter(location => location.id !== action.payload)
        return {
          ...state,
          locations: filteredData
        }
    

      default:
        return state;
    }
  };
  