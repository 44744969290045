// api config
import { API_URL } from '../api/config'

// register and login
export const apiRegister = () => `${API_URL}/register`
export const apiUserAuth = () => `${API_URL}/login`
export const apiUserAuthAdmin = () => `${API_URL}/loginAdmin`

// user
export const apiGetUser = (userId) => `${API_URL}/user/${userId}`
export const apiGetAllUsers = () => `${API_URL}/users`
export const apiGetAllUsersByLocation = (locationId) => `${API_URL}/users?location_id=${locationId}`
export const apiGetAllUsersByLocationAndStatus = (locationId, status) => `${API_URL}/users?location_id=${locationId}&status=0`
export const apiUpdateUser = (userId) => `${API_URL}/user/${userId}`
export const apiDeleteUser = (userId) => `${API_URL}/user/${userId}`
export const apiUpdateUserPassword = () => `${API_URL}/user/password`

// subscription levels
export const apiGetSubscriptionLevel = (subscriptionLevelId) => `${API_URL}/subscriptionLevels/${subscriptionLevelId}`
export const apiUpdateSubscriptionLevel = (subscriptionLevelId) => `${API_URL}/subscriptionLevels/${subscriptionLevelId}`
export const apiGetAllSubscriptionLevels = (stripeId) => `${API_URL}/subscriptionLevels?stripe_id=${stripeId}`
export const apiGetAllSubscriptionLevelsByLocation = (stripeId, location_id) => `${API_URL}/subscriptionLevels?stripe_id=${stripeId}&location_id=${location_id}`
export const apiCreateSubscriptionLevel = () => `${API_URL}/subscriptionLevels`

// subscription
export const apiGetSubscription = (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}`
export const apiUpdateSubscription = (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}`
export const apiDeleteSubscription = (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}`
export const apiGetAllSubscriptions = (stripeId) => `${API_URL}/subscriptions?stripe_id=${stripeId}`
export const apiCreateSubscription = () => `${API_URL}/subscriptions`

// locations
export const apiGetLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiUpdateLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiDeleteLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiGetLocations = () => `${API_URL}/locations`
export const apiAddLocation = () => `${API_URL}/locations`

// sub locations
export const apiGetSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiUpdateSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiDeleteSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiGetSubLocations = () => `${API_URL}/sublocations`
export const apiGetSubLocationsAdmin = () => `${API_URL}/sublocationsAdmin`
export const apiAddSubLocation = () => `${API_URL}/sublocations`
export const apiGetSubLocationsByLocationId = () => `${API_URL}/location_sublocations`

// available sessions
export const apiGetAllAvailableSessionsByLocation= (locationId) => `${API_URL}/availableSessions?location_id=${locationId}`
export const apiAddAvailableSession = () => `${API_URL}/availableSessions`
export const apiGetAllAvailableSessions = () => `${API_URL}/availableSessions`
export const apiGetAllAvailableSessionsAdmin = () => `${API_URL}/availableSessionsAdmin`
export const apiGetOneAvailableSession = (sessionId) => `${API_URL}/availableSessions/${sessionId}`
export const apiUpdateAvailableSession = (sessionId) => `${API_URL}/availableSessions/${sessionId}`
export const apiDeleteAvailableSession = (sessionId) => `${API_URL}/availableSessions/${sessionId}`
export const apiSessionsAvailableReport = () => `${API_URL}/availableSessions/allstats/stats`

// scheduled
export const apiGetAllScheduledSessionsByLocation= (locationId) => `${API_URL}/scheduledSessions?location_id=${locationId}`
export const apiAddScheduledSession = () => `${API_URL}/scheduledSessions`
export const apiGetAllScheduledSessions = () => `${API_URL}/scheduledSessions`
export const apiGetAllScheduledSessionsAdmin = () => `${API_URL}/scheduledSessionsAdmin`
export const apiGetOneScheduledSession = (sessionId) => `${API_URL}/scheduledSessions/${sessionId}`
export const apiGetAllScheduledSessionsByAvailableSessionId = (availableSessionId) => `${API_URL}/scheduledSessionsByAvailableSessionId/${availableSessionId}`
export const apiUpdateScheduledSession = (sessionId) => `${API_URL}/scheduledSessions/${sessionId}`
export const apiDeleteScheduledSession = (sessionId) => `${API_URL}/scheduledSessions/${sessionId}`
export const apiSessionsScheduledReport = () => `${API_URL}/scheduledSessions/allstats/stats`

// media
export const apiGetMediaById = (mediaId) => `${API_URL}/media_by_id?id=${mediaId}`
export const apiDeleteMedia = (mediaId) => `${API_URL}/media_delete?id=${mediaId}`
export const apiUpdateMedia = (mediaId) => `${API_URL}/media_update?id=${mediaId}`
export const apiAllMedia = () => `${API_URL}/media`
export const apiAddMedia = () => `${API_URL}/media`

// disclaimers
export const apiGetDisclaimerById = (disclaimerId) => `${API_URL}/disclaimer_by_id?id=${disclaimerId}`
export const apiDeleteDisclaimer = (disclaimerId) => `${API_URL}/disclaimer_delete?id=${disclaimerId}`
export const apiUpdateDislclaimer = (disclaimerId) => `${API_URL}/disclaimer_update?id=${disclaimerId}`
export const apiAllDisclaimers = () => `${API_URL}/disclaimers`
export const apiAddDisclaimer = () => `${API_URL}/disclaimers`

export const apiEmail = () => `https://ing0g4m48e.execute-api.us-east-1.amazonaws.com/vixalopexstageapi/vixalopexlambdaresource`

// sts mailer
export const apiConfirmationEmail = () => `https://0fag77d9uk.execute-api.us-east-1.amazonaws.com/dev/stsmailer`

// stripe
export const apiGetStripeSecretKey = (price, stripeId) => `${API_URL}/stripe?price=${price}&stripe_id=${stripeId}`
export const apiGetStripePromiseKey = (stripeId) => `${API_URL}/stripeP?stripe_id=${stripeId}`
export const apiPostStripeCreateCustomer = () => `${API_URL}/stripeCreateCustomer`
export const apiGetStripeCustomerSecretKey = () => `${API_URL}/stripeCustomerKey`
export const apiPostStripeCreateSubscription = () => `${API_URL}/stripeCreateSubscription`
export const apiPostStripeUpdateSubscription = () => `${API_URL}/stripeUpdateSubscription`
export const apiPostStripeCancelSubscription = () => `${API_URL}/stripeCancelSubscription`