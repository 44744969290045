// ui action constants
import {
  UI_SET_DATE,
  UI_SET_SELECTED_DATE
} from "../actions/types";
  
// default ui reducer
let date = new Date(Date.now())
let date2 = new Date(Date.now())

// Add a day
date.setDate(date.getDate() + 1)
// Add two days
date2.setDate(date.getDate() + 1)

const defaultState = {
  currentDate: new Date(Date.now()).toLocaleString().split(',')[0],
  currentDatePlusOne: date.toLocaleString().split(',')[0],
  currentDatePlusTwo: date2.toLocaleString().split(',')[0],
  selectedDate: null
}

// ui reducer
export default function init(state = defaultState, action) {
  switch (action.type) {

    case UI_SET_DATE:
      return {
        ...state,
        currentDate: action.payload,
        currentDatePlusOne: action.payload,
        currentDatePlusTwo: action.payload,
      }

      case UI_SET_SELECTED_DATE:
        return {
          ...state,
          selectedDate: action.payload
      }

    default:
      return state
  }
}
  