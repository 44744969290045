import React from 'react';
import logo from "../../../assets/images/STS_Text.png"


function LogoName() {
  return <img style={{
    flex: 1,
    mixBlendModde: "difference",
    color: "transparent",
    // filter: "drop-shadow(6px 2px 10px black)",
    height: 34,
    justifyContent: "center"
  }} src={logo} alt="LogoName" />;
}

export default LogoName;