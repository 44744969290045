/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Button from "../CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import {
  Facebook,
  Twitter,
  Instagram,
  Edit,
  Streetview,
  Person,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { openCustomDialog } from "../../../config/reduxstore/actions/ui/openCustomDialog"
import { setAlert } from "../../../config/reduxstore/actions/ui/setAlert"
import { setActiveComponent } from "../../../config/reduxstore/actions/ui/setActiveComponent"
import { tokenExpired } from '../../../config/reduxstore/actions/user/tokenExpired'
import { clearLocalStorage } from "../../../config/utilities/localStorage"
import { setActiveAdminComponent } from "../../../config/reduxstore/actions/ui/setActiveAdminComponent"
import getSubscription from "../../../config/requests/subscriptions/getSubscription";
import getSubscriptionLevels from "../../../config/requests/subscriptionLevels/getSubscriptionLevels";
// import getUser from "../../../config/requests/user/getUser";

import styles from "../../../assets/headerLinksStyle";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.token);
  const name = useSelector((state) => state.user.currentUser.name);
  const stripe_id = useSelector((state) => state.user.currentUser.stripe_id);
  const subscription_id = useSelector((state) => state.user.currentUser.subscription_id);
  const subscriptionActive = useSelector((state) => state.subscriptions.subscription.subscription_active)
  const locationId = useSelector((state) => state.user.currentUser.location_id);

  const handleLogout = (timerLogout) => {
    setIsLoading(true)
    clearLocalStorage()
    dispatch(tokenExpired())
    dispatch({ type: "DESTROY_AVAILABLE_SESSION" });
    dispatch(tokenExpired())
    if(timerLogout){
      // dispatch(
      //   setActiveComponent(`Main`)
      // )
      // dispatch(
      //   setActiveAdminComponent(``)
      // )
      dispatch(setAlert('warning', `You were logged out as your session has expired, please login!`))
    }else{
      dispatch(setAlert('success', `Logged out successfully!`))
    }
    setIsLoading(false)
    // dispatch(
    //   setActiveComponent(`Main`)
    // )
    // dispatch(
    //   setActiveAdminComponent(``)
    // )
  };
  
  useEffect(() => {
    // getUser(dispatch, setIsLoading, currentUser.id)
    // get subscription levels and pass stripe_id and price to stripe component
    if (token){
      if (subscription_id && !isAdmin){
        getSubscription(dispatch, setIsLoading, subscription_id)
      }
      if(stripe_id && locationId && !isAdmin){
        getSubscriptionLevels(dispatch, setIsLoading, parseInt(stripe_id), parseInt(locationId))
      }
    }
  }, [dispatch, props.selectedLocationSessionPrice, stripe_id, subscription_id, token])

  useEffect(() => {
    if (currentUser && currentUser.last_login){
      const myInterval = setInterval(() => {
        const lastLogin = new Date(currentUser.last_login)
        const dateNow = new Date(Date.now())
        const lastLoginPlus1Day = lastLogin.setDate(lastLogin.getDate() + 1);
        const lastLoginPlus1DayFormatted = new Date(lastLoginPlus1Day)
        // test ("dateNow: ", dateNow)
        // test ("lastLoginPlus1DayFormatted: ", lastLoginPlus1DayFormatted)
        if(dateNow >= lastLoginPlus1DayFormatted || currentUser.application_id !== 3){
          // test true
          handleLogout(true)
        }else{
          // test false
        }
      }, 10000);
      return () => {
        clearInterval(myInterval);
      };
    }
    // if (currentUser && currentUser.application_id !== 3){
    //   handleLogout(true)
    // }
  }, [currentUser])

  const handleOpenDialog = (dialogName) => {
    if(stripe_id && subscription_id){
      dispatch(
        openCustomDialog(`dialogs/${dialogName}`, {stripe_id: stripe_id, subscription_id: subscription_id})
      )
    }else{
      dispatch(
        openCustomDialog(`dialogs/${dialogName}`)
      )
    }
  };

  const handleSetActiveComponent = (componentName) => {
    if (isAdmin && componentName === 'CurrentLocations') {
      clearLocalStorage()
      dispatch(tokenExpired())
      dispatch(
        setActiveAdminComponent(``)
      )
      dispatch(
        setActiveComponent(`Views/${componentName}`)
      )
    } else (
      dispatch(
        setActiveComponent(`Views/${componentName}`)
      )
    )
    props.handleDrawerToggle()
  };

  const handleSetActiveComponentLoginAdmin = () => {
    if (token && token !== "" && isAdmin && userLevel){
      if(parseInt(userLevel) === 2){
        dispatch(
          setActiveComponent('Views/Main2')
        )
      }else{
        dispatch(setAlert('error', 'You do not have access to the admin portal'))
      }
    } else {
      dispatch(
        setActiveComponent('LoginAdmin')
      )
    }
  };

  const headerLinksList = () => {
    if(name && (!subscriptionActive) && !isAdmin){
      return [
        <Typography onClick={() => handleLogout(false)} className={classes.dropdownLink}>
        Logout
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserSubscribe`)} className={classes.dropdownLink}>
        Subscribe
      </Typography>,
      ]
    }
    if(!name) {
      return [
        <Typography onClick={() => handleOpenDialog('UserLogin')} className={classes.dropdownLink}>
        Login
      </Typography>,
        <Typography onClick={handleSetActiveComponentLoginAdmin} className={classes.dropdownLink}>
        Login Admin
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserRegister`)} className={classes.dropdownLink}>
        Create Account
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserSubscribe`)} className={classes.dropdownLink}>
        Subscribe
      </Typography>,
      ]
    }
    if(name && (subscriptionActive)) {
      return [
      <Typography onClick={() => handleLogout(false)} className={classes.dropdownLink}>
        Logout
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserUpdateSubscription`)} className={classes.dropdownLink}>
        Update Subscription
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserCancelSubscription`)} className={classes.dropdownLink}>
        Cancel Subscription
      </Typography>,
      ]
    }
    if(name && isAdmin){
      return [
        <Typography onClick={() => handleLogout(false)} className={classes.dropdownLink}>
        Logout
      </Typography>,
      <Typography onClick={handleSetActiveComponentLoginAdmin} className={classes.dropdownLink}>
        Admin Portal
      </Typography>,
      ]
    }else{
    <Typography onClick={handleSetActiveComponentLoginAdmin} className={classes.dropdownLink}>
      Admin Portal
    </Typography>
    }
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Contact"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Edit}
          dropdownList={[
            <Typography onClick={() => handleOpenDialog('Contact')} className={classes.dropdownLink}>
              Contact Us
            </Typography>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Explore"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Streetview}
          dropdownList={[
            <Typography onClick={() => handleSetActiveComponent(`CurrentLocations`)} className={classes.dropdownLink}>
              Current Locations
            </Typography>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/vixalopex"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <Twitter className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/vixalopex/"
            target="_blank"
            className={classes.navLink}
          >
            <Facebook className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/vixalopex/"
            target="_blank"
            className={classes.navLink}
          >
            <Instagram className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={name ? name : "Login"}
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Person}
          dropdownList={headerLinksList()}
        />
      </ListItem>
    </List>
  );
}
