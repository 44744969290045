/* eslint-disable */
// user action constants
import {
    SCHEDULED_SESSION,
    CREATE_SCHEDULED_SESSION_SUCCESS,
    SCHEDULED_SESSIONS_SUCCESS,
    UPDATE_SCHEDULED_SESSION_SUCCESS,
    DELETE_SCHEDULED_SESSION_SUCCESS,
    SCHEDULED_SESSIONS_REPORT,
    SCHEDULED_SESSIONS_BY_AVAILABLE_SESSION_ID,
    SCHEDULED_SESSIONS_BY_USER_COUNT,
    SCHEDULED_SESSIONS_LIMIT,
  } from "../actions/types";
  
  // default available session state
  const defaultState = {
    scheduledSession: {
        id: null,
        available_session_id: null,
        name: null,
        phoneNumber: null,
        email: null,
        location_id: null,
        sub_location_id: null,
        disclaimerAgreed: null,
        paymentSuccess: null,
        paymentId: null,
        paymentAmount: null,
        paymentBypassedWithSubscription: null,
        userIdAffiliatedWSubscription: null,
        date: null
    },
    newSession: {},
    scheduledSessions: [],
    scheduledSessionsByAvailableSessionId: [],
    scheduledSessionsReport: [],
    scheduledSessionsByUserCount: null,
    scheduledSessionsLimit: null
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case SCHEDULED_SESSION:
        return {
          ...state,
          scheduledSession: {
            id: action.payload.id,
            available_session_id: action.payload.available_session_id,
            name: action.payload.name,
            phoneNumber: action.payload.phoneNumber,
            email: action.payload.email,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id,
            disclaimerAgreed: action.payload.disclaimerAgreed,
            paymentSuccess: action.payload.paymentSuccess,
            paymentId: action.payload.paymentId,
            paymentAmount: action.payload.paymentAmount,
            paymentBypassedWithSubscription: action.payload.paymentBypassedWithSubscription,
            userIdAffiliatedWSubscription: action.payload.userIdAffiliatedWSubscription,
            date: action.payload.date
          }
        }

      case SCHEDULED_SESSIONS_SUCCESS:
        return {
          ...state,
          scheduledSessions: action.payload
        }

      case SCHEDULED_SESSIONS_BY_AVAILABLE_SESSION_ID:
        return {
          ...state,
          scheduledSessionsByAvailableSessionId: action.payload
        }

      case CREATE_SCHEDULED_SESSION_SUCCESS:
        const existingArray = state.scheduledSessions;
        existingArray.push(action.payload);
        return {
          ...state,
          newSession: action.payload,
          scheduledSessions: existingArray
        }

      case UPDATE_SCHEDULED_SESSION_SUCCESS:
        const filteredArray = state.scheduledSessions.filter(scheduledSession => scheduledSession.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          scheduledSessions: filteredArray,
        }

      case DELETE_SCHEDULED_SESSION_SUCCESS:
        const filteredData = state.scheduledSessions.filter(scheduledSession => scheduledSession.id !== action.payload)
        return {
          ...state,
          scheduledSessions: filteredData
        }
    
      case SCHEDULED_SESSIONS_REPORT:
        return {
          ...state,
          scheduledSessionsReport: action.payload
        }

      case SCHEDULED_SESSIONS_BY_USER_COUNT:
        return {
          ...state,
          scheduledSessionsByUserCount: action.payload
        }

      case SCHEDULED_SESSIONS_LIMIT:
        return {
          ...state,
          scheduledSessionsLimit: action.payload
        } 

      default:
        return state;
    }
  };
  