/* eslint-disable */
// request urls
// import { apiGetAllSubscriptionLevels } from '../../api/urls'
import { apiGetAllSubscriptionLevelsByLocation, apiGetAllSubscriptionLevels } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'

// actions
import { getSubscriptionLevels } from '../../reduxstore/actions/subscriptionLevel/getSubscriptionLevels'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (dispatch, setLoading, stripeId, location_id) => {
  let request = null
  if(stripeId && location_id){
    request = await get(apiGetAllSubscriptionLevelsByLocation(stripeId, location_id))
  }
  if(stripeId && !location_id){
    request = await get(apiGetAllSubscriptionLevels(stripeId))
  }

  const { data, error } = request

  // handle success
  data && dispatch(getSubscriptionLevels(data.data))

  // set loading state
  setLoading(false)

  // handle failure
  error && dispatch(setAlert('error', error))
}
