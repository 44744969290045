/* eslint-disable */
// user action constants
import {
    SUBSCRIPTION,
    SUBSCRIPTIONS_SUCCESS,
    CREATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_SUCCESS,
    DELETE_SUBSCRIPTION_SUCCESS,
    SUBSCRIPTIONS_REPORT,
    SUBSCRIBER_SUBSCRIPTION
  } from "../actions/types";
  
  // default subscritions state
  const defaultState = {
    subscription: {
        id: null,
        stripe_id: null,
        stripe_subscription_id: null,
        stripe_subscription_item_id: null,
        stripe_customer_id: null,
        subscription_active: null,
        subscription_expiration: null,
        subscription_level: null,
        paymentSuccess: null,
        paymentId: null,
        paymentAmount: null,
        location_id: null,
        sub_location_id: null,
        date: null
    },
    newSubscription: {},
    subscriberSubscription: {},
    subscriptions: [],
    subscriptionsReport: [],
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case SUBSCRIPTION:
        return {
          ...state,
          subscription: {
            id: action.payload.id,
            stripe_id: action.payload.stripe_id,
            stripe_subscription_id: action.payload.stripe_subscription_id,
            stripe_subscription_item_id: action.payload.stripe_subscription_item_id,
            stripe_customer_id: action.payload.stripe_customer_id,
            subscription_active: action.payload.subscription_active,
            subscription_expiration: action.payload.subscription_expiration,
            subscription_level: action.payload.subscription_level,
            paymentSuccess: action.payload.paymentSuccess,
            paymentId: action.payload.paymentId,
            paymentAmount: action.payload.paymentAmount,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id,
            date: action.payload.date
          }
        }

      case SUBSCRIPTIONS_SUCCESS:
        return {
          ...state,
          subscriptions: action.payload
        }

      case CREATE_SUBSCRIPTION_SUCCESS:
        const existingArray = state.subscriptions;
        existingArray.push(action.payload);
        return {
          ...state,
          newSubscription: action.payload,
          subscription: action.payload,
          subscriptions: existingArray
        }

      case UPDATE_SUBSCRIPTION_SUCCESS:
        const filteredArray = state.subscriptions.filter(subscription => subscription.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          subscriptions: filteredArray,
          subscription: {
            id: action.payload.id,
            stripe_id: action.payload.stripe_id,
            stripe_subscription_id: action.payload.stripe_subscription_id,
            stripe_subscription_item_id: action.payload.stripe_subscription_item_id,
            stripe_customer_id: action.payload.stripe_customer_id,
            subscription_active: action.payload.subscription_active,
            subscription_expiration: action.payload.subscription_expiration,
            subscription_level: action.payload.subscription_level,
            paymentSuccess: action.payload.paymentSuccess,
            paymentId: action.payload.paymentId,
            paymentAmount: action.payload.paymentAmount,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id,
            date: action.payload.date
          }
        }

      case DELETE_SUBSCRIPTION_SUCCESS:
        const filteredData = state.subscriptions.filter(subscription => subscription.id !== action.payload)
        const existingSubscription = { ...state.subscription, subscription_active: false }
        return {
          ...state,
            subscriptions: filteredData,
            subscription: existingSubscription
        };
    
      case SUBSCRIPTIONS_REPORT:
        return {
          ...state,
          subscriptionsReport: action.payload
        }

      case SUBSCRIBER_SUBSCRIPTION:
        return {
          ...state,
          subscriberSubscription: action.payload
        }

      default:
        return state;
    }
  };
  