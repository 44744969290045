import React from 'react'
import ReactPlayer from 'react-player'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components

import styles from "../../assets/youtubeStyle";

const useStyles = makeStyles(styles);

export default function VideoPlayer() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
      <ReactPlayer
                className='react-player fixed-bottom'
                url= 'https://vixalopexsts.s3.amazonaws.com/sts_sign_up.mp4'
                width='100%'
                height='100%'
                controls = {true}
                playing={true}
                muted={true}
                />
      </div>
    </div>
  );
}